import React from 'react';
import ThemeConfig from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from '~/routes';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  return (
    <ThemeConfig>
          <Router history={history}>
              {renderRoutes(routes)}
          </Router>
    </ThemeConfig>
  );
}

export default App;
